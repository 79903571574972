import classNames from 'classnames';
import { FC, PropsWithChildren, ReactNode } from 'react';
type BreakpointSwitchProps = PropsWithChildren<{
  className?: string;
  fallback: ReactNode;
  breakpoint?: 'md' | 'lg' | 'xl' | '2xl';
  inline?: boolean;
}>;
export const BreakpointSwitch: FC<BreakpointSwitchProps> = ({
  children,
  className,
  fallback,
  breakpoint,
  inline
}) => {
  const Tag = inline ? 'span' : 'div';
  return <>
      <Tag className={classNames(className, {
      'md:hidden print:hidden': breakpoint === 'md',
      'lg:hidden print:hidden': breakpoint === 'lg',
      'xl:hidden print:hidden': breakpoint === 'xl',
      '2xl:hidden print:hidden': breakpoint === '2xl'
    })} data-sentry-element="Tag" data-sentry-source-file="breakpoint-switch.tsx">
        {fallback}
      </Tag>
      <Tag id="breakpoint-container" className={classNames(className, 'hidden', {
      'md:block': !inline && breakpoint === 'md',
      'lg:block': !inline && breakpoint === 'lg',
      'xl:block': !inline && breakpoint === 'xl',
      '2xl:block': !inline && breakpoint === '2xl',
      'md:inline': inline && breakpoint === 'md',
      'lg:inline': inline && breakpoint === 'lg',
      'xl:inline': inline && breakpoint === 'xl',
      '2xl:inline': inline && breakpoint === '2xl'
    })} data-sentry-element="Tag" data-sentry-source-file="breakpoint-switch.tsx">
        {children}
      </Tag>
    </>;
};