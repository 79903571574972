'use client';

import { useSize } from '@spikemark/shared-hooks';
import { Button } from '@spikemark/ui/components/ui/button';
import classNames from 'classnames';
import { X } from 'lucide-react';
import { FC, useCallback, useRef, useState } from 'react';
import YouTubeEmbed from 'react-youtube';
const isAndroid = /android/gi.test(navigator.userAgent);
export const YouTubePlayer: FC<{
  videoId: string;
}> = ({
  videoId
}) => {
  const [showVideo, setShowVideo] = useState(false);
  const yt = useRef<YouTubeEmbed>(null);
  const container = useRef<HTMLDivElement>(null);
  const size = useSize(container);
  const handlePlay = useCallback(() => {
    const player = yt.current?.getInternalPlayer();
    player.playVideo();
    setShowVideo(true);
  }, []);
  const handleClose = useCallback(() => {
    const player = yt.current?.getInternalPlayer();
    player.stopVideo();
    setShowVideo(false);
  }, []);
  return <>
      <div className="mx-2 pl-3 lg:pl-5 pr-5 lg:pr-10 py-2 lg:py-4 flex flex-row cursor-pointer bg-transparent transition-colors duration-200 rounded-full group" onClick={handlePlay}>
        <div className="m-0 p-0 aspect-square h-[73px] w-auto cursor-pointer scale-75 lg:scale-100">
          <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 75 75" fill="none" className="group-hover:text-clippd-pink transition-colors duration-200" data-sentry-element="svg" data-sentry-source-file="youtube-player.tsx">
            <path fillRule="evenodd" clipRule="evenodd" d="M37.3822 74.1345C57.7517 74.1345 74.2644 57.6218 74.2644 37.2523C74.2644 16.8828 57.7517 0.370117 37.3822 0.370117C17.0127 0.370117 0.5 16.8828 0.5 37.2523C0.5 57.6218 17.0127 74.1345 37.3822 74.1345ZM55.5657 38.9216C56.7637 38.1312 56.7637 36.3734 55.5657 35.5829L30.1261 18.7972C28.7964 17.9198 27.0246 18.8734 27.0246 20.4665L27.0246 54.0381C27.0246 55.6312 28.7964 56.5848 30.1261 55.7074L55.5657 38.9216Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="youtube-player.tsx" />
          </svg>
        </div>

        <div className="text-start pl-4 self-center text-sm lg:text-xl">
          <p className="font-bold uppercase">Watch</p>
          <p className="font-bold tracking-wider">Expert guide to key holes</p>
        </div>
      </div>

      <div className={classNames('inset-0 overflow-clip pointer-events-none', {
      'absolute invisible': !isAndroid || !showVideo,
      'lg:z-10 lg:visible lg:pointer-events-auto': showVideo,
      'fixed z-100 pointer-events-auto': isAndroid && showVideo
    })}>
        <div ref={container} className="absolute inset-0">
          <YouTubeEmbed ref={yt} videoId={videoId} opts={{
          width: size.width,
          height: size.height,
          playerVars: {
            autoplay: 0,
            fs: 1,
            rel: 0,
            playsinline: 0,
            hd: 0,
            poster: 'maxresdefault',
            vq: 'hd1080'
          }
        }} title="The North Course at Omni La Costa" loading="eager" data-sentry-element="YouTubeEmbed" data-sentry-source-file="youtube-player.tsx" />

          <Button className="absolute top-20 right-8 pointer-events-auto" size="icon" onClick={handleClose} data-sentry-element="Button" data-sentry-source-file="youtube-player.tsx">
            <X size={24} data-sentry-element="X" data-sentry-source-file="youtube-player.tsx" />
          </Button>
        </div>
      </div>
    </>;
};