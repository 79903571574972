'use client';

import { DialogContent } from '@radix-ui/react-dialog';
import { useClientConfig } from '@spikemark/core';
import { PlayCircle, X } from 'lucide-react';
import { ImageProps } from 'next/image';
import { FC } from 'react';
import { CloudinaryImage } from './cloudinary-image';
import { Button } from './ui/button';
import { Dialog, DialogClose, DialogTrigger } from './ui/dialog';
import { BreakpointSwitch } from './breakpoint-switch';
export const CloudinaryVideo: FC<ImageProps & {
  loop?: boolean;
  autoPlay?: boolean;
  muted?: boolean;
  controls?: boolean;
  fullScreen?: boolean;
}> = ({
  src,
  alt,
  loop,
  autoPlay,
  muted,
  controls,
  fullScreen
}) => {
  const {
    cloudinary
  } = useClientConfig();
  const fallbackImage = <CloudinaryImage className="absolute inset-0 object-cover" src={src} alt={alt} fill />;
  return <div className="relative w-full h-full overflow-clip" data-sentry-component="CloudinaryVideo" data-sentry-source-file="cloudinary-video.tsx">
      {fallbackImage}
      <video className="absolute inset-0 object-cover object-center outline-none" preload="auto" autoPlay={autoPlay} loop={loop} muted={muted} playsInline={!fullScreen} controls={controls} style={{
      minWidth: '100%',
      minHeight: '100%',
      width: 'auto',
      height: 'auto'
    }}>
        <source src={String(src).startsWith('http') ? `${src}` : `${cloudinary.basePath}${src}`} type="video/mp4" />
        {fallbackImage}
      </video>
    </div>;
};
export const CloudinaryVideoPlayer: FC<ImageProps & {
  className?: string;
}> = ({
  src,
  alt,
  className
}) => {
  return <BreakpointSwitch breakpoint="lg" fallback={<div className={className}>
          <CloudinaryVideo alt={alt} src={src} controls fullScreen />
        </div>} data-sentry-element="BreakpointSwitch" data-sentry-component="CloudinaryVideoPlayer" data-sentry-source-file="cloudinary-video.tsx">
      <Dialog data-sentry-element="Dialog" data-sentry-source-file="cloudinary-video.tsx">
        <DialogTrigger asChild className="cursor-pointer relative overflow-clip hover:opacity-90 transition-opacity duration-300" data-sentry-element="DialogTrigger" data-sentry-source-file="cloudinary-video.tsx">
          <div className={className}>
            <CloudinaryImage src={src} alt={alt} data-sentry-element="CloudinaryImage" data-sentry-source-file="cloudinary-video.tsx" />
            <div className="absolute inset-0 flex items-center justify-center">
              <PlayCircle size={64} className="text-white opacity-70 stroke-1" data-sentry-element="PlayCircle" data-sentry-source-file="cloudinary-video.tsx" />
            </div>
          </div>
        </DialogTrigger>
        <DialogContent className="bg-primary-dark fixed inset-0 top-header" data-sentry-element="DialogContent" data-sentry-source-file="cloudinary-video.tsx">
          <CloudinaryVideo alt={alt} src={src} controls autoPlay data-sentry-element="CloudinaryVideo" data-sentry-source-file="cloudinary-video.tsx" />
          <DialogClose className="fixed top-header mt-8 right-8 z-60" data-sentry-element="DialogClose" data-sentry-source-file="cloudinary-video.tsx">
            <Button size="icon" data-sentry-element="Button" data-sentry-source-file="cloudinary-video.tsx">
              <X size={24} className="text-white" data-sentry-element="X" data-sentry-source-file="cloudinary-video.tsx" />
            </Button>
          </DialogClose>
        </DialogContent>
      </Dialog>
    </BreakpointSwitch>;
};